import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['first', 'Int'],
  ['page', 'Int'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String'],
  ['tie_breaker_param', 'String'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['is_collections_needed', 'Boolean'],
  ['is_scraps_needed', 'Boolean']
]);

export default function USER_BOOKMARK(responseString = '') {
  return gql`
  query UserBookmark(${parametersTypeString}){
    userBookmark(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data{
        scrap {
            id
            user_id
            user_name
            display_name
            avatar
            title
            desc
            status
            state
            private_key
            service
            meta
            meta_title
            meta_desc
            price
            created_at
            updated_at
            url
            domain
            favicon
            original_favicon
            annotations {
                    highlighted_text
            }
            annotations_count
            documents_count
            documents {
            file_name
            file_type
            file_path
            }
            audios_count
            audios{
            file_name
            file_path
            }
            videos_count
            videos{
            file_name
            file_path
            }
            images_count
            images {
            file_name
            file_path
            order
            file_status
            file_config
            desc
            }
            tags {
            slug
            }
            collections{
            id
            }
            reaction_count
        }
        collection {
            id
            user_id
            slug
            title
            desc
            status
            state
            private_key
            sort_by
            sort_param
            view_type
            cover_image
            user_name
            display_name
            avatar
            nickname
            show_author
            used_at
            created_at
            updated_at
            members {
                user_id
                display_name
                avatar
                user_name
                is_trusted
            } 
            reaction_count
            comment_count 
            is_trusted
        }
        user {
            user_id
            user_name
            display_name
            avatar
        }
        bookmark_order {
            scrap_id
            child_collection_id
        }
        scrapsCount {
          id
          scraps_count
        }
      }
    }
  } 
  `;
}
