export default function parseJustification(justification: string) {
  const justificationIndex = justification.indexOf('<justification>');
  const justificationEndIndex = justification.indexOf('</justification>');

  if (justificationIndex === -1 || justificationEndIndex === -1) {
    return null;
  }

  const justificationText = justification.slice(
    justificationIndex + 15,
    justificationEndIndex
  );

  // check if there is a number in the justification text
  // get all the numbers and put them in an array
  const numbers = justificationText.match(/\d+/g);
  const listOfNumbers = numbers?.map(Number);

  return {
    justification: justificationText,
    scrapIds: listOfNumbers,
  };
}
