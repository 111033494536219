import React from 'react';

interface TrustIconProps {
  type?: 'fill' | 'outline';
}

const TrustIcon = ({ type }: TrustIconProps) => {
  if (type === 'outline') {
    return (
      <svg
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.0145 0.110788C17.6874 -0.0369295 17.3126 -0.0369295 16.9855 0.110788C10.771 2.91745 7.98123 3.81417 1.09996 5.04854C0.533345 5.15019 0.109412 5.62567 0.073163 6.2002C-1.2479 27.1387 15.7441 34.3904 17.0331 34.9095C17.3327 35.0302 17.6673 35.0302 17.967 34.9095C19.2559 34.3904 36.2479 27.1387 34.9269 6.2002C34.8906 5.62567 34.4667 5.15019 33.9001 5.04854C27.0188 3.81417 24.229 2.91745 18.0145 0.110788ZM17.5 32.3891C14.7143 31.1257 1.93118 24.3848 2.52056 7.33068C8.73904 6.17625 11.7468 5.19857 17.5 2.62068C23.2532 5.19857 26.261 6.17625 32.4795 7.33068C33.0688 24.3848 20.2857 31.1257 17.5 32.3891ZM25.6544 11.3163C26.1665 10.7317 26.1021 9.84755 25.5105 9.34151C24.9189 8.83547 24.0243 8.89915 23.5122 9.48375L14.688 19.5579L11.4081 16.3825C10.8492 15.8414 9.95224 15.8505 9.40471 16.4028C8.85717 16.9551 8.86638 17.8415 9.42528 18.3826L13.7815 22.6001C14.0607 22.8704 14.4406 23.0147 14.8311 22.9988C15.2216 22.983 15.5882 22.8083 15.844 22.5163L25.6544 11.3163Z"
          fill="black"
        />
      </svg>
    );
  }

  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="shield" fill-rule="evenodd" clip-rule="evenodd"
        d="M16.9855 0.110788C17.3126 -0.0369295 17.6874 -0.0369295 18.0145 0.110788C24.229 2.91745 27.0188 3.81417 33.9 5.04854C34.4667 5.15019 34.8906 5.62567 34.9268 6.2002C36.2479 27.1387 19.2559 34.3904 17.9669 34.9095C17.6673 35.0302 17.3327 35.0302 17.0331 34.9095C15.7441 34.3904 -1.24791 27.1387 0.0731564 6.2002C0.109405 5.62567 0.533338 5.15019 1.09995 5.04854C7.98123 3.81417 10.771 2.91745 16.9855 0.110788Z"
        fill="#eb6f19" />
      <path className="shield-check" fill-rule="evenodd" clip-rule="evenodd"
        d="M25.5105 9.34151C26.1021 9.84755 26.1665 10.7317 25.6544 11.3163L15.844 22.5163C15.5882 22.8083 15.2216 22.983 14.8311 22.9988C14.4406 23.0147 14.0607 22.8704 13.7815 22.6001L9.42527 18.3826C8.86638 17.8415 8.85717 16.9551 9.4047 16.4028C9.95223 15.8505 10.8492 15.8414 11.4081 16.3825L14.688 19.5579L23.5122 9.48375C24.0243 8.89915 24.9189 8.83547 25.5105 9.34151Z"
        fill="white" />
    </svg>
  )
}

export default TrustIcon;