import React, { useEffect, useRef } from 'react';
import {
  Button,
  ChatContainer,
  Conversation,
  InputToolbox,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  Sidebar,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import * as config from '../../settings.json';
import './ChatStyles.css';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ChatIcon, LeftArrowIcon, NewClose } from '../Global/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  formatMessages,
  formatThread,
  getThreads,
  getThreadsChat, removeJustification,
  saveChat
} from "./helpers/threads";
import { ThreadType } from './helpers/threadsType';
import { canPaste } from '../../redux/action/pasteDataAction';
import MarkdownReader from './MarkdownReader';
import NewPopup from '../Global/NewPopup';
import parseJustification from './helpers/parseJustification';
import Citation from "./Citation";

const stopIcon = faStop as IconProp;
const copyIcon = faCopy as IconProp;

interface ChatProps {
  title?: string;
  scrapIds?: (string | number)[];
  noUserFilter?: boolean;
  collectionId?: string; // used for either collection or a group id
  isGroup?: boolean;
  userList?: object[];
}

const Chat = (props: ChatProps) => {
  const { title, scrapIds, noUserFilter, collectionId, isGroup, userList } = props;
  const [showChat, setShowChat] = React.useState(false);
  const [threadList, setThreadList] = React.useState([]);
  const [selectedThread, setSelectedThread] = React.useState<ThreadType>(null);
  const [messagesList, setMessagesList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [streaming, setStreaming] = React.useState(false);
  const [controller, setController] = React.useState(null);
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [isMobile, setIsMobile] = React.useState(false);

  // state to show all the threads regardless of the context
  // if false, it will only show threads that are in the context of the screen
  const [showAllThreads, setShowAllThreads] = React.useState(false);

  const isServer = typeof window === 'undefined';

  // get user id from redux store
  const userId = useSelector(
    (state: { user: { user_id: string } }) => state.user.user_id
  );

  const inputRef = useRef(null);
  const lastMessage = useRef(null);
  const dispatch = useDispatch();

  // on component mount get the list of threads
  useEffect(() => {
    getThreads(userId).then(async (threads) => {
      const allThreads = formatThread(threads);
      setThreadList(formatThread(allThreads));

      if(!isServer) {
        // check if the url has openThread query param
        // the value of the query param is the thread id
        // set it as an active thread
        const urlParams = new URLSearchParams(window.location.search);
        const openThread = urlParams.get('openThread');
        if (openThread) {
          const thread = allThreads.find((t) => t.threadId === openThread);
          if (thread) {
            setSelectedThread(thread);
            await getThreadMessages(openThread);
            setShowChat(true);
            inputRef.current?.focus();
            scrollToBottom();
          }
        }
      }
    });
  }, [userId]);

  function cancelStreaming() {
    if (controller) {
      controller.abort();
      setController(null);
    }
  }

  async function getResponse(message: string) {
    setLoading(true);

    const messageListLength = messagesList.length;
    const newMessageList = [...messagesList];
    // add a new message to the list using the index of the last message
    newMessageList[messageListLength] = {
      props: {
        model: {
          message: message,
          sentTime: '0 seconds',
          sender: 'me',
          direction: 'outgoing',
          position: 'last',
        },
      },
    };
    newMessageList[messageListLength + 1] = {
      props: {
        model: {
          message: '',
          sentTime: '0 seconds',
          sender: 'me',
          direction: 'incoming',
          position: 'last',
        },
      },
    };

    // add a new message to the list
    // @ts-ignore
    setMessagesList([...newMessageList]);
    let scopeController = controller;
    if (!scopeController) {
      scopeController = new AbortController();
      setController(scopeController);
    }

    const signal = scopeController?.signal;

    let requestParams = {
      prompt: message,
      scrapIds,
    };

    if (!noUserFilter) {
      requestParams['userId'] = userId;
    }

    const chatHistory = newMessageList.map((m) => {
      if (m.props.model.direction === 'incoming') {
        return {
          role: 'assistant',
          content: m.props.model.message,
        };
      } else {
        return {
          role: 'user',
          content: m.props.model.message,
        };
      }
    });

    let chatHistoryForRequest = chatHistory?.slice(0, chatHistory.length - 2);
    // if the length of the chatHistoryForRequest is greater than 5, only keep the recent 5 messages
    if (chatHistoryForRequest.length > 5) {
      chatHistoryForRequest = chatHistoryForRequest.slice(
        chatHistoryForRequest.length - 5,
        chatHistoryForRequest.length
      );
    }

    await fetch(config.ragURL, {
      method: 'POST',
      body: JSON.stringify({
        ...requestParams,
        // remove the current two messages from the chat history
        chatHistory: chatHistoryForRequest,
      }),
      signal: signal,
      headers: {
        'Content-Type': 'text/event-stream',
      },
    })
      .then((data) => {
        return data.body;
      })
      .then(async (val) => {
        setStreaming(true);

        const reader = val.getReader();
        const decoder = new TextDecoder('utf-8');
        setLoading(false);

        let hasJustification = false;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log('Stream complete');
            return;
          }

          // decode and format the response
          let content = decoder.decode(value);
          const lines = content.split('\n');
          const parsedLines = lines
            .map((line) => {
              return line.replace(/^data: /, '').trim();
            })
            .filter((line) => line !== '' && line !== '[DONE]')
            .map((line) => JSON.parse(line));

          // join all the responses together
          const result = parsedLines.map((line) => line.response).join('');

          // add new text to the message
          const prev = newMessageList;
          const updatedMessage = prev[prev.length - 1].props.model.message;

          // FOR DEBUGGING: if the <justification> tag is found, remove that tag and don't add the text to the previous message
          // the update is only for production, keep it in dev
          // if (updatedMessage.includes('<jus') || hasJustification) {
          //   if (hasJustification) {
          //     // don't add the text to the previous message
          //     continue;
          //   }
          //   hasJustification = true;
          //   // find the position of the <justification> tag and remove everything after that including the tag
          //   const justificationIndex = updatedMessage.indexOf('<jus');
          //   prev[prev.length - 1].props.model.message = updatedMessage.slice(0, justificationIndex);
          // } else {
          //   prev[prev.length - 1].props.model.message += result;
          // }

          const newMessage = prev[prev.length - 1].props.model.message + result;
          prev[prev.length - 1].props.model.message = newMessage;

          const justification = parseJustification(newMessage);
          if (justification) {
            console.log(justification);
          }

          setMessagesList([...prev]);
        }
      })
      .catch((err) => {
        if (signal.aborted) {
          console.log('Streaming cancelled');
        }
      })
      .finally(() => {
        setStreaming(false);
      });

    let path = null;
    if (!isServer) {
      path = window.location.pathname;
      console.log('This is the value of path: ', path);
    }

    // meta-data for the collection and group details
    const collectionInfo = {
      collectionId,
      groupId: isGroup ? collectionId : null,
      path,
      label: title,
    }

    if (!selectedThread) {
      const newThread = {
        title: message.trim(),
        threadId: null,
        updatedAt: new Date().toISOString(),
        collectionId: null,
        groupId: null,
        path: null,
        label: null,
      };
      const savedChat = await saveChat(
        newThread,
        { content: message, role: 'user' },
        userId,
        collectionInfo
      );
      newThread.threadId = savedChat?.threadId;
      newThread.collectionId = collectionId;
      newThread.groupId = isGroup ? collectionId : null;
      newThread.path = path;
      newThread.label = title;

      await saveChat(
        newThread,
        {
          content:
            newMessageList[newMessageList.length - 1].props.model.message,
          role: 'assistant',
        },
        userId,
        collectionInfo
      );

      // update the thead list by deleting the first element and adding the new thread
      // update the first element of the thread list and update the threadid
      setThreadList([newThread, ...threadList]);
      setSelectedThread(newThread);
    } else {
      // save the chat message to the server
      await saveChat(
        selectedThread,
        { content: message, role: 'user' },
        userId,
        collectionInfo
      );

      // save the chat response, which is the last message in the list
      await saveChat(
        selectedThread,
        {
          content:
            newMessageList[newMessageList.length - 1].props.model.message,
          role: 'assistant',
        },
        userId,
        collectionInfo
      );
    }
  }

  function copyToClipboard(text: string) {
    if (!isServer) {
      navigator.clipboard.writeText(text || '');
    }
  }

  function scrollToBottom() {
    if (!isServer && !streaming) {
      setTimeout(() => {
        lastMessage.current?.scrollIntoView();
      }, 0);
    }
  }

  // create an event listener if it is not server
  // on screen resize below 670px, set isMobile to true
  useEffect(() => {
    if (!isServer) {
      const handleResize = () => {
        if (window.innerWidth < 670) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  // if we switch from mobile to desktop, show the sidebar
  useEffect(() => {
    if (!isMobile) {
      setShowSidebar(true);
    }
  }, [isMobile]);

  // get the list of messages for the selected thread
  async function getThreadMessages(threadId: string) {
    const messages = (await getThreadsChat(threadId)) || [];
    const formattedMessages = formatMessages(messages);
    setMessagesList(formattedMessages);
  }

  /**
   * When the chat is opened on mobile,
   * make sure it opens up with the message and the sidebar stays hidden
   * if there is a selected thread, don't do anything
   */
  function toggleChatMobile() {
    setShowChat(true);

    if (isMobile && !selectedThread) {
      setShowSidebar(false);
    }
  }

  if(!userId) {
    return null;
  }

  // Make the label based on where it needs to go, collection, group or My Scrap
  let selectedLabel = 'My Scraps';
  if(selectedThread?.collectionId) {
    selectedLabel = 'the collection';
    if(selectedThread.label) {
      selectedLabel = selectedThread.label;
    }
  }
  if(selectedThread?.groupId) {
    selectedLabel = 'the share group';
    if(selectedThread.label) {
      selectedLabel = selectedThread.label;
    }
  }

  // check if selected thread and active collection/group match
  let isThreadMatch =
    (
      selectedThread &&
      (
        // match the selected thread with the active collection or group
        (
          selectedThread.path &&
          (
            +selectedThread.collectionId === +collectionId ||
            +selectedThread.groupId === +collectionId
          )
        ) ||
        (
          // the page is my Scrap
          !collectionId && !selectedThread.collectionId && !selectedThread.groupId
        )
      )
    );

  // if there is no selected thread, I am starting a new conversation, mark is thread match as true
  if(!selectedThread) {
    isThreadMatch = true;
  }

  let forMyScraps = false;
  if(selectedLabel === 'My Scraps') {
    forMyScraps = true;
  }

  // for cases with incomplete information and backward compatibility
  // the conversation is from a collection but the path to the connection is missing
  // treat it like a conversation from My Scraps
  if(!collectionId && !isThreadMatch && !selectedThread?.path) {
    isThreadMatch = true;
  }
  if(!selectedThread?.path && (selectedThread?.collectionId || selectedThread?.groupId)) {
    selectedLabel = 'My Scraps';
  }

  // filter the thread list and only show threads that are in the context of the screen
  const filteredThreadsList = threadList.filter((thread) => {
    if(showAllThreads) {
      return true;
    }

    if (
      (+collectionId === +thread.collectionId || +collectionId === +thread.groupId) ||
      (!collectionId && !thread.collectionId && !thread.groupId)
    ) {
      return true;
    }
  });

  return createPortal(
    <>
      {!showChat && (
        <div className="chat__bubble" onClick={() => toggleChatMobile()}>
          <ChatIcon />
        </div>
      )}

      {showChat && (
        <NewPopup
          className="chat__container"
          size="Large"
          header={{
            customRender: () => (
              <div className="new-popup__header-inner-container">
                {isMobile && !showSidebar && (
                  <button
                    className="new-popup__header-button hoverState__with-white-text"
                    onClick={() => {
                      setShowSidebar(true);
                    }}
                  >
                    <LeftArrowIcon className="new-popup__header-button__icon" />
                  </button>
                )}

                <h3 className="new-popup__header-heading">
                  {title ? title : 'My Scraps'}
                </h3>

                <button
                  className="new-popup__header-button hoverState__with-white-text"
                  onClick={() => {
                    setShowChat(false);
                  }}
                >
                  <NewClose className="new-popup__header-button__icon " />
                </button>
              </div>
            ),
          }}
          controlled={{
            show: showChat,
            setShow: setShowChat,
          }}
        >
          <div className="chat__wrapper">
            <MainContainer>
              {(showSidebar || !isMobile) && (
                <Sidebar position="left" style={{ width: '100px' }}>
                  <span
                    className="chat__thread-add"
                    onClick={() => {
                      if (isMobile) {
                        setShowSidebar(false);
                      }

                      setMessagesList([]);
                      setSelectedThread(null);
                    }}
                  >
                    + New conversation
                  </span>

                  {filteredThreadsList.map((thread, i) => (
                    <Conversation
                      key={i}
                      name={thread.title}
                      title={thread.title}
                      onClick={async () => {
                        setSelectedThread(thread);
                        setMessagesList([]);

                        // hide the sidebar in mobile view
                        if (isMobile) {
                          setShowSidebar(false);
                        }

                        // make request to get the messages for the thread
                        await getThreadMessages(thread.threadId);

                        // scroll to the bottom of the chat
                        scrollToBottom();
                      }}
                      active={
                        selectedThread?.threadId === thread.threadId ||
                        selectedThread?.threadId === null
                      }
                      className={
                        +collectionId === +thread.collectionId ||
                        +collectionId === +thread.groupId ||
                        (!collectionId &&
                          !thread.collectionId &&
                          !thread.groupId)
                          ? 'active'
                          : ''
                      }
                    ></Conversation>
                  ))}

                  <span
                    className="chat__thread-add all-conversation-toggle"
                    onClick={() => {
                      setShowAllThreads(!showAllThreads);
                    }}
                  >
                    {showAllThreads ? 'Hide other' : 'Show all'} conversations
                  </span>
                </Sidebar>
              )}

              {(!showSidebar || !isMobile) && (
                <ChatContainer>
                  <MessageList
                    typingIndicator={
                      loading ? (
                        <TypingIndicator content="Getting your answers" />
                      ) : null
                    }
                  >
                    {messagesList.length > 0 &&
                      messagesList.map((m, i) => {
                        return (
                          <>
                            <Message
                              className="my class"
                              key={i}
                              model={{
                                position: m.props?.model?.position,
                                direction: m.props?.model?.direction,
                                type: 'custom',
                              }}
                            >
                              <Message.CustomContent>
                                {/* add ref called lastMessage if it is the last message */}
                                <div
                                  className="chat__message"
                                  ref={
                                    i + 1 === messagesList.length
                                      ? lastMessage
                                      : null
                                  }
                                >
                                  {m.props.model.direction === 'incoming' &&
                                    (isServer ? (
                                      removeJustification(
                                        m.props?.model?.message
                                      )
                                    ) : (
                                      <MarkdownReader
                                        markdown={removeJustification(
                                          m.props?.model?.message
                                        )}
                                        shouldLoadMarkdown={!isServer}
                                      />
                                    ))}

                                  {m.props.model.direction !== 'incoming' &&
                                    m.props?.model?.message}
                                </div>

                                {m.props?.model?.direction === 'incoming' &&
                                  !streaming &&
                                  m.props?.model?.message !== '' && (
                                    <div className="chat__actions">
                                      <span
                                        className="chat__copy"
                                        onClick={() =>
                                          copyToClipboard(
                                            m.props?.model?.message
                                          )
                                        }
                                      >
                                        <Button
                                          icon={
                                            <FontAwesomeIcon icon={copyIcon} />
                                          }
                                        />
                                      </span>

                                      <Citation
                                        message={m.props?.model?.message}
                                        scrapIds={m.props?.model?.scrapIds}
                                        justification={
                                          m.props?.model?.justification
                                        }
                                        collectionId={
                                          selectedThread?.groupId ||
                                          selectedThread?.collectionId
                                        }
                                        isGroup={
                                          !!(isGroup || selectedThread?.groupId)
                                        }
                                        userList={userList}
                                      />
                                    </div>
                                  )}
                              </Message.CustomContent>
                            </Message>
                          </>
                        );
                      })}
                  </MessageList>
                  ;{/* @ts-ignore */}
                  <InputToolbox>
                    {/* only if the selected thread did not initiate from the current place (collection, share, My Scrap) */}
                    {!isThreadMatch && selectedThread && (
                      <div className="chat__collection-message">
                        <Link
                          className="create-scrap-nav-icon__add hoverState__with-white-text"
                          to={`${selectedThread.path || '/'}?openThread=${
                            selectedThread.threadId
                          }`}
                        >
                          Click here to continue in {selectedLabel}
                        </Link>
                      </div>
                    )}

                    {/* Show the input field */}
                    {isThreadMatch && (
                      <MessageInput
                        activateAfterChange
                        sendButton={!streaming}
                        disabled={streaming}
                        attachButton={false}
                        ref={inputRef}
                        placeholder={
                          title
                            ? `Ask ${title}`
                            : isGroup
                            ? 'Ask this share group'
                            : `Ask My Scraps`
                        }
                        onFocus={() => {
                          dispatch(canPaste(false));
                        }}
                        onBlur={() => {
                          dispatch(canPaste(true));
                        }}
                        onSend={async (_htmlMessages, message) => {
                          if (messagesList.length === 0) {
                            // today's date in the format of "Month Day"
                            const today = new Date().toLocaleDateString(
                              'en-US',
                              {
                                month: 'short',
                                day: 'numeric',
                              }
                            );

                            setThreadList([
                              {
                                title: message.trim(),
                                date: today,
                                messages: [
                                  {
                                    props: {
                                      model: {
                                        message: message,
                                        sentTime: '0 seconds',
                                        sender: 'me',
                                        direction: 'outgoing',
                                        position: 'single',
                                      },
                                    },
                                  },
                                ],
                              },
                              ...threadList,
                            ]);
                          }

                          await getResponse(message);
                        }}
                      />
                    )}

                    {streaming && (
                      <Button
                        icon={<FontAwesomeIcon icon={stopIcon} />}
                        onClick={() => cancelStreaming()}
                      />
                    )}
                  </InputToolbox>
                  ;
                </ChatContainer>
              )}
            </MainContainer>
          </div>
        </NewPopup>
      )}
    </>,
    isServer ? null : document.getElementById('portal-chat')
  );
};

export default Chat;
