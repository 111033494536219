import React, { useEffect } from 'react';
import * as config from '../../../../settings.json';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import './ContactImportStyles.css';

/**
 * Mutation to save the list of follows
 */
const UPSERT_CONTACTS = gql`
  mutation upsertSocialTrusts($social_trusts: [SocialTrustInput]) {
    upsertSocialTrusts(social_trusts: $social_trusts) {
        data {
            socialTrusts {
                id
            }
        }
        messages {
            error
            success
        }
    }
  }
`;

/**
 * Query to get the list of follows
 */
const GET_FOLLOWS = gql`
  query socialTrusts($first: Int!, $page: Int!) {
    socialTrusts(first: $first, page: $page) {
      data {
        socialTrusts {
          user_id
          social_response
          service_user_id
          service_username
          social_response
        }
      }
    }
  }`;

/**
 * Save user social information to the database
 */
const SAVE_USER_SOCIAL_INFO = gql`
  mutation upsertSocialSync($service_id: Int!, $service_response: String, $service_user_id: String, $service_username: String) {
    upsertSocialSync(service_id: $service_id, service_response: $service_response, service_user_id: $service_user_id, service_username: $service_username) {
      data {
        socialSync {
          id
        }
      }
      status
    }
  }
`;

const tempUser =  {
  "public_metrics": {
    "followers_count": 333,
    "following_count": 155,
    "tweet_count": 25574,
    "listed_count": 27,
    "like_count": 1671
  },
  "created_at": "2009-09-07T11:50:55.000Z",
  "profile_image_url": "https://pbs.twimg.com/profile_images/1427319706772406272/WCW0FOPx_normal.jpg",
  "location": "Dehradun, India",
  "verified": false,
  "username": "paritosh_pundir",
  "protected": false,
  "url": "https://t.co/iQypwOmPSs",
  "id": "72264840",
  "name": "Paritosh Pundir",
  "description": "🚀 Helping people solve problems through\n👨🏻‍💻 CODE, 🎨 DESIGN & 🖋 WORDS\n\nFounder DoRemember"
}

const ContactImport = () => {
  const isServer = typeof window === 'undefined';
  const [ loading, setLoading ] = React.useState(false);
  const [ following, setFollowing ] = React.useState([]);
  // create a lazy load query
  const [getFollows] = useLazyQuery(GET_FOLLOWS);

  // save the user's social information to the database
  const [upsertSocialSync] = useMutation(SAVE_USER_SOCIAL_INFO);

  // create a mutation to save the list of follows
  const [upsertContacts] = useMutation(UPSERT_CONTACTS);

  // when the component loads and there is code parameter in the URL, we will exchange it for an access token
  // get the values the code and make a request to '/api/twitter' to exchange it for an access token
  React.useEffect(() => {
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      setLoading(true);

      fetch(`/api/twitter?code=${code}&state=${state}`)
        .then(res => res.json())
        .then(data => {
          const listOfFollows = data?.follows?.following;
          const userData = data?.user;
          saveUserSocials(userData);
          setFollowing(listOfFollows);
          saveContacts(listOfFollows);
        }).finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    // callback from twitter, no need to fetch the user's follows
    if(code && state) {
      return;
    }

    // get user's follows
    setLoading(true);
    getFollows({
      variables: {
        first: 100,
        page: 1,
      },
    }).then((res) => {
      const response = res?.data?.socialTrusts;
      if (!response) return;

      // parse the social_response key to display the user details and update the follow list
      const following = response?.data?.socialTrusts.map((item) => {
        try {
          return JSON.parse(item.social_response);
        } catch (error) {
          return {};
        }
      });

      if (following.length) {
        setFollowing(following);
      }

      setLoading(false);
    }).finally(() => setLoading(false));
  }, []);

  function saveUserSocials(userData = null) {
    upsertSocialSync({
      variables: {
        service_id: 1,
        service_response: JSON.stringify(userData),
        service_user_id: userData.id,
        service_username: userData.username,
      },
    }).then((res) => {
      const response = res?.data?.upsertSocialSync?.data;
      if(!response) return;
      console.log('Saved user data for X', response);
    })
  }

  function saveContacts(data = null) {
    const followingList = data ? data : following;

    const social_trusts = followingList.map((item) => ({
      service_id: 1,
      service_user_id: item.user_id,
      service_username: item.screen_name,
      social_response: JSON.stringify(item),
    }));

    setLoading(true);

    upsertContacts({
      variables: {
        social_trusts,
      },
    }).then((res) => {
      const response = res?.data?.upsertSocialTrusts;
      if(!response) return;

      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  }

  /**
   * Redirect to twitter to get the access token
   */
  function redirectToTwitter() {
    const clientId = config.twitterClientId;
    const redirectUri = `${config.appUrl}/profile`;
    const scopes = ['tweet.read', 'users.read', 'follows.read']; // Adjust as needed

    // Generate a code verifier and code challenge (using PKCE)
    const codeChallenge = 'challenge';

    // Step 1: Construct the authorization URL
    const authorizationUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(' ')}&state=state&code_challenge=${codeChallenge}&code_challenge_method=plain`;

    // redirect to the authorization URL
    window.location.href = authorizationUrl;
  }
  return (
    <div>
      <h1 className="x-follows__header">X follows</h1>
      {
        loading && (
          <div className="x-follows__loading">
            <span>Syncing...</span>
          </div>
        )
      }

      {
        (following?.length === 0 && !loading) && (
          <button onClick={redirectToTwitter}>Connect X</button>
        )
      }

      <div>
        { (following?.length > 0 && !loading) && (
          following?.map((user, index) => (
            <a className="x-follows__link" href={`https://twitter.com/${user.screen_name}`} target="_blank" rel="noreferrer" key={index}>
              <div className="x-follows__user" key={index}>
                <img src={user.profile_image} alt={user.screen_name} />
                <p>
                  <h2>{user.name}</h2>
                  <h3>{user.screen_name}</h3>
                  <span>{user.description}</span>
                </p>
              </div>
            </a>
          ))
        )}
      </div>

    </div>
  );
}

export default ContactImport;