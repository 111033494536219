import React, { useEffect, useRef } from 'react';
import ContactCard from './UiComponents/ContactCard';
import ContactsControl from './UiComponents/ContactsControl';
import ContactCardOptions from './UiComponents/ContactCardOptions';
import SearchContacts from './UiComponents/SearchContacts';
import { GlobalSearchUserType } from '../../types/globalSearchDataType';
import './styles.css';
interface Proptypes {
  isMutationProcessing?: boolean;
  children: (contact: GlobalSearchUserType) => React.ReactElement;
  renderContactsControl: () => React.ReactElement;
  data?: Array<GlobalSearchUserType>;
  renderSearch:()=>React.ReactElement;
  renderNonScrappiUser?:()=>React.ReactElement;
  loading?: boolean;
  onLoadMore: () => void;
  label:'contacts'|'invitations' | 'trusts' | 'trusted-scrappers';
}
const MyContacts = ({
  isMutationProcessing,
  children,
  renderContactsControl,
  data,
  loading,
  onLoadMore,
  renderSearch,
  label,
  renderNonScrappiUser
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>()

  const handleScroll = () => {
    var element = ref.current;
    const { scrollHeight, scrollTop, clientHeight } = element;
    if (scrollHeight - scrollTop - 100 <= clientHeight) {
      if(!loading) {
        if (onLoadMore) onLoadMore();
        // loadMoreHandler();
      }
      
    }
  };
 

  // THIS USEEFFECT TAKES CARE OF LOADING MORE IF THE PAGE IS NOT SCROLLABLE YET FOR ONSCROLL EVENT TO GET FIRED
  useEffect(() => {
    if(ref?.current && !loading && !isMutationProcessing) {
      const element = ref.current;
      
      const { scrollTop } = element;
      if(scrollTop === 0) {
        handleScroll();
      }
    }    
  }, [ref, data.length, loading, isMutationProcessing])

  let el;

  if (loading && !data) {
    el = null;
  } else {
    
    el = (
      <ul className='my-contacts__list'>
        {data.map((user) => (
          <li className='my-contacts__list-item' key={user.email}>
            {children(user)}
          </li>
        ))}
      </ul>
    )
  }
  
  if (label === 'trusts' || label === 'trusted-scrappers') {
    return (
      <div className="my-contacts">
        {renderContactsControl()}
        {el}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={`my-contacts${
        isMutationProcessing ? ' my-contacts--disabled' : ''
      }`}
      onScroll={handleScroll}
    >
      {renderContactsControl()}
      {label === 'contacts' && renderSearch()}
      {renderNonScrappiUser()}
      {el}
    </div>
  );
};

export {
  MyContacts as default,
  ContactCard,
  ContactsControl,
  ContactCardOptions,
  SearchContacts
};
